import React from "react"
import { graphql } from "gatsby"
import Layout from "../../modules/layout"
import SEO from "../../modules/seo"
import BigHeader from "../../components/bigHeader"
import QuickSelect from "../../components/quickSelect"
import { renderDynamicComponents } from "../../components"

const SecondPage = ({ data: { allContentfulHotelSeite } }) => {
  const content = allContentfulHotelSeite.nodes[0]

  return (
    <Layout theme="strandperle">
      <SEO title={content.title} />
      <BigHeader 
        image={content.headerImage.fluid} 
        alt={content.headerImage.description} 
        additionalClass="home"
        title={content.title}
        subTitle={content.subTitle}
        strer={content.strerImHeader}
      />
      <QuickSelect linkToZimmer={true} theme="strandperle" />

      {content && renderDynamicComponents(content.components, 'strandperle')}

    </Layout>
  )
}

export const pageQuery = graphql`
query strandperleHomeQuery {
  allContentfulHotelSeite(filter: {hotel: {eq: "Strandperle"} type: {eq: "Startseite"}}) {
    nodes {
      id
      title
      subTitle
      strerImHeader {
        message
        prefix
        suffix
        link
      }
      headerImage {
        fluid(maxWidth: 1600 quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
        description
      }
      components {
        ... on ContentfulComponentPromotionTeaser {
          id
          promotionTeaserTitle
          promotionTeaserBgImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageRightTop {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageRightBottom {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageRight1 {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageLeft {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserContent {
            json
          }
          promotionTeaserTitleImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentImageWall {
          id
          imageWallImages {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentContentTeaser {
          id
          contenTeaserTitle
          contenTeaserContent {
            json
          }
          contenTeaserCarousel {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          contentIdOptional
          internal {
            type
          }
        }
        ... on ContentfulComponentThreeTeaser {
          id
          threeTeaserContent1
          threeTeaserContent2
          threeTeaserContent3
          threeTeaserIcon1
          threeTeaserIcon2
          threeTeaserIcon3
          contenTeaserTitle1
          threeTeaserTitle2
          threeTeaserTitle3
threeTeaserLink1 {
        ... on ContentfulLink {
          id
          title
          linkTarget
        }
        ... on ContentfulDocument {
          id
          title
          document {
            file {
              url
            }
          }
        }
      }
      threeTeaserLink2 {
        ... on ContentfulLink {
          id
          title
          linkTarget
        }
        ... on ContentfulDocument {
          id
          title
          document {
            file {
              url
            }
          }
        }
      }
      threeTeaserLink3 {
        ... on ContentfulLink {
          id
          title
          linkTarget
        }
      }
      darkBackground
          internal {
            type
          }
        }
        ... on ContentfulComponentGeneralContentFullWidth {
          textCentered
          twoColumns
          handwritten
          contenTeaserTitle
          darkBackground
          GeneralContentFullWidthContent {
            json
          }
          internal {
            type
          }
          contentIdOptional
        }
... on ContentfulComponentFourTeaser {
          id
          text1
          text2
          text3
          text4
          icon1
          icon2
          icon3
          icon4
          title
          title1
          title2
          title3
          title4
          internal {
            type
          }
        }
        ... on ContentfulComponentRoomType {
          id
          roomTypeTitle
          roomTypePrice
          roomTypeShortDescription
          roomTypeContent {
            json
          }
          roomTypeImages {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentPromotionTeaser2 {
          id
          promotionTeaserTitle
          promotionTeaserImageRightTop {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageRightBottom {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserTitleImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageLeft {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageBottomLeft {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserImageBottomRight {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          promotionTeaserContent {
            json
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentGeneralContent {
          id
          generalContent {
            json
          }
          generalContentTitle
          textCentered
          generalContentImage {
            fixed(width: 200) {
              src
              srcSet
              srcWebp
              srcSetWebp
              aspectRatio
              width
              height
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentGeneralContentTwoColumn {
          contentLeft {
            json
          }
          contentRight {
            json
          }
          handwritten
          generalContentTitle
          internal {
            type
          }
          textCentered
          titleLeft
          titleRight
          contentIdOptional
        }
        ... on ContentfulComponentRestaurantBooking {
          title
          internal {
            type
          }
        }
        ... on ContentfulComponentImageSlider {
          id
          darkBackground
          ImageSliderImages {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentGoogleMaps {
          title
          internal {
            type
          }
        }
... on ContentfulComponentTrustedYou {
          title
          internal {
            type
          }
        }
        ... on ContentfulComponentContactForm {
          title
          internal {
            type
          }
        }
        ... on ContentfulComponentEvent {
          eventTitle
          EventDateTime
          eventContent {
            json
          }
          eventImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentSpecialOffer {
          seekdaOfferId
          offerSubTitle
          OfferTitle
          OfferContent {
            json
          }
          OfferImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          internal {
            type
          }
        }
... on ContentfulComponentTwoTeaser {
          OfferTitle
          OfferContent {
            json
          }
          OfferImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          linkText
          linkTarget
          offerTitle2
          offerContent2 {
            json
          }
          offerImage2 {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          linkText2
          linkTarget2
          internal {
            type
          }
        }
      }
    }
  }
}
`

export default SecondPage
